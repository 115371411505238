import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

import Layout from '../../components/layout';
import Toc from '../../components/toc';
import TocMobile from '../../components/tocMobile';
import SEO from '../../components/seo';
import LanguageSelector from '../../components/languageSelector';
import { IconNavigationArrowChevronNormalRight } from '@bcmi-labs/react-icons';

import './commonPageTemplate.scss';

export default function Template({ data, pageContext }) {
  const { mdx } = data; // data.markdownRemark holds our post data
  const { frontmatter, body, tableOfContents } = mdx;

  const { locale: currentLocale, availableLocales } = pageContext;
  const { t } = useTranslation();
  const supportedLocales = process.env.GATSBY_LOCALES.split(',');

  // Remove breadcrumb of not navigable root
  const crumbs = pageContext.breadcrumb?.crumbs || [];
  let updatedCrumbs = crumbs.filter(
    crumb =>
      crumb.crumbLabel !== 'Home' && crumb.crumbLabel !== 'Tutorial' && !supportedLocales.includes(crumb.crumbLabel)
  );

  updatedCrumbs = updatedCrumbs.map(crumb => {
    crumb.crumbLabel = t(`crumbs:${crumb.crumbLabel}`);
    return crumb;
  });

  return (
    <Layout currentLocale={currentLocale} withMobileToc={availableLocales.length > 1}>
      <SEO title={frontmatter.title} description={frontmatter.description} image={frontmatter.featuredImage} />
      {tableOfContents.items ? <TocMobile toc={tableOfContents} title={t('common:toc')} /> : null}
      {availableLocales.length > 1 ? (
        <LanguageSelector locales={availableLocales} currentLocale={currentLocale} />
      ) : null}
      <main className="common-page page">
        {updatedCrumbs.length > 1 ? (
          <div className="main-header">
            <Breadcrumb
              crumbs={updatedCrumbs}
              crumbSeparator={<IconNavigationArrowChevronNormalRight />}
              crumbLabel={frontmatter.title}
              disableLinks={[frontmatter.slug]}
            />
          </div>
        ) : null}

        <section>
          <div className="toc-container">
            {tableOfContents.items ? <Toc toc={tableOfContents} title={t('common:toc')} /> : null}
          </div>
          <div className="page-content">
            <h1>{frontmatter.title}</h1>
            <MDXRenderer>{body}</MDXRenderer>
            <div className="main-footer">
              {t('common:lastRevision')} {frontmatter.date}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      tableOfContents(maxDepth: 4)
      frontmatter {
        date
        slug
        title
        description
        featuredImage {
          childImageSharp {
            fixed(width: 1200, height: 800) {
              src
            }
          }
        }
      }
    }
  }
`;
