import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import Collapsible from 'react-collapsible';

import LayoutContext from './layoutContext';

import Toc from './toc';
import { IconNavigationArrowCaretNormalDown } from '@bcmi-labs/react-icons';

import './tocMobile.scss';

const TocMobile = ({ toc, title }) => {
  const [HFPortal, setHFPortal] = useState(null);
  const [open, setOpen] = useState(false);
  const layoutCtx = useContext(LayoutContext);

  const timerId = React.useRef(null);

  useEffect(() => {
    if (!window || layoutCtx.isEmbedded == null) {
      return;
    }

    const _getHeader = () => {
      const timeOut = 500;
      const portalElem = document.getElementById(!layoutCtx.isEmbedded ? 'ino-portal-element' : 'header');
      if (portalElem) {
        setHFPortal(typeof document !== `undefined` ? portalElem : null);
        clearTimeout(timerId.current);
        return;
      }

      timerId.current = setTimeout(_getHeader, timeOut);
    };

    _getHeader();
    return () => clearTimeout(timerId.current);
  }, [layoutCtx]);

  if (!HFPortal) {
    return null;
  }

  const panel = (
    <div className="panel">
      <span>{title}</span>
      <div>
        <IconNavigationArrowCaretNormalDown />
      </div>
    </div>
  );

  const handleClick = event => {
    if (event.target.tagName === 'A') {
      setOpen(false);
    }
  };

  return ReactDOM.createPortal(
    <div className="toc-mobile-wrapper" onClick={handleClick}>
      <Collapsible
        trigger={panel}
        open={open}
        onTriggerOpening={() => setOpen(true)}
        onTriggerClosing={() => setOpen(false)}
      >
        <Toc toc={toc} title={''} />
      </Collapsible>
    </div>,
    HFPortal
  );
};

export default TocMobile;
